import React from "react";

const moment = require("moment");
const config = require("../utilities/config");

class Viewx extends React.Component {
  state = {
    response: null,
  };

  componentDidMount() {
    const id = this.props.id;

    fetch(`${config.back}/getSamples/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ response: data });
      });
  }

  render() {
    if (this.state.response) {
      return (
        <div>
          <table align="center">
            <thead>
              <tr>
                <th align="justify"> Test Date </th>
                <th align="justify"> Well </th>
                <th align="justify"> Test </th>
              </tr>
            </thead>
            <tbody>
              {this.state.response.map((item, index) => (
                <tr key={index}>
                  <td>{moment(item.test_date).format("MMMM Do YYYY")}</td>
                  <td>{item.well}</td>
                  <td>{item.test}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
  }
}

export default Viewx;
