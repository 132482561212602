import React from "react";
const config = require("../utilities/config");
const helper = require("../utilities/helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/well_groups";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr><td>{props.item.id}</td>
    <td><a href={`/user/graph_sets?wellGroupId=${props.item.id}`}>{props.item.name}</a></td><td>{props.item.wells}</td><td>{props.item.site}</td>
    <td>{props.item.wellType}</td><td>{props.item.method}</td>
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    if (!q.well_type)
      q.well_type = "all";
    this.state = {
      show: false,
      rows: null,
      name:null,
      email:"",
      error:null,
      image: null,
      site : q.site,well_type : q.well_type,
    };
  }




fillList()
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get"+"&site="+encodeURI(this.state.site)+"&well_type="+encodeURI(this.state.well_type))
  .then(res => {
//    console.log(res.result)
    this.setState({showForm: false, rows: res.result, options: res.options});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, name: item.name,wells: item.wells,site: item.site,wellType: item.wellType,method: item.method, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, name: this.state.name,wells: this.state.wells,site: this.state.site,wellType: this.state.wellType,method: this.state.method,}, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "add", site: this.state.site, wellType: this.state.wellType}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Groundwater Data Warehouse";
let listItems;
//!!select_vars!!

if (this.state.rows)
{

  var siteList = this.state.options.siteList.map((item) =>
<option value={item.name}>{item.name}</option>);
var well_typeList = this.state.options.well_typeList.map((item) =>
<option value={item.id}>{item.name}</option>);


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );


}
return(

<div>
  site: <select name='site' value={this.state.site} onChange={this.hic.bind(this)}>{siteList}</select><br/>
well_type: <select name='well_type' value={this.state.well_type} onChange={this.hic.bind(this)}>{well_typeList}</select><br/>

  <button onClick={() => {
    window.location = "/user/well_groups?"+"site="+encodeURI(this.state.site)+"&well_type="+encodeURI(this.state.well_type)

  }}>Refresh</button>
  <br/><br/>
<button onClick={this.addItem.bind(this)}>Add Item</button><br/>

<table className="table">
    <td>Id</td><td>Name</td>
	<tbody>
    
  {listItems}
  
	</tbody>
  </table>

  <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="name" title="name" onChange={this.hic.bind(this)} value={this.state.name}/></div>
<div><TextPrompt name="wells" title="wells" onChange={this.hic.bind(this)} value={this.state.wells}/></div>
<div><TextPrompt name="site" title="site" onChange={this.hic.bind(this)} value={this.state.site}/></div>
<div><TextPrompt name="wellType" title="wellType" onChange={this.hic.bind(this)} value={this.state.wellType}/></div>
<div><TextPrompt name="method" title="method" onChange={this.hic.bind(this)} value={this.state.method}/></div>

        <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;

</Modal>

</div>
)

}
}

export default ModalList1
