import React from "react";
import "../css/menu.css";
import {Space} from "./../modules/omwui/misc";

const config = require('../utilities/config');
const helper = require('../utilities/helper')

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: sessionStorage.getItem('site'),
    }
  }

  handleSelect(event) {
    fetch(config.back+'/user/set_site', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: "same-origin",
      body: JSON.stringify({site: event.target.value})
    }).then(response => response.json()).then(res => {
      if (res.code == 200) {
        sessionStorage.setItem('site', res.site);

        this.setState({site: res.site});
        
        window.location.reload(false);
      }
    });
  }

  render() {
    var secure = "";

    if (config.back === "https://localhost:3010/api" && sessionStorage.getItem("uid") == null) {
        secure = <a href='https://localhost:3010/'>Secure</a>
    }
  
    let userName = sessionStorage.getItem("name");

    console.log(userName);

    if (userName) {
      return (
        <div>
          <b>Hello:</b>{" " + userName}
          <div>
            <span><b>Database:</b>{" "}</span>
            <select value={this.state.site} onChange={this.handleSelect.bind(this)}>
              <option value="tulare">Tulare</option>
              <option value="merced">Merced</option>
            </select><Space/>
            <span>Open: <a href="#" onClick={async () => {

                var res = await helper.postToServer("/user/transfer", {site: this.state.site});
                var data = res.result;
                window.open(`https://${this.state.site}.ebagroup.com/scripts/postdata.php?dw=li2&username=${userName}&seq=${data.seq}`, '_blank');


            }}>{this.state.site}.ebagroup.com</a></span>

          </div>
        <nav id="menucontainer">
          <ul id="menu" className="topmenu">
            <li className="topmenu">
              <a href="/">
                <span>Home</span>
              </a>
            </li>
            <li className="topmenu">
              <a href={`https://${this.state.site}.ebagroup.com/users/data.php`}>
                <span>Settings</span>
              </a>
              <ul>
              <li>
                  <a href="/user/methods">
                    <span>Methods</span>
                  </a>
                </li>
                <li>
                  <a href="/user/headings">
                    <span>Headings</span>
                  </a>
                </li>
                <li>
                  <a href="/user/graph_sets">
                    <span>Graph Sets</span>
                  </a>
                </li>
                <li>
                  <a href="/user/well_groups">
                    <span>Well Groups</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="topmenu">
              <a><span>Graphs</span></a>
              <ul>
                <li>
                <a href={`https://${this.state.site}.ebagroup.com/users/list_detections.php`}>
                    <span>Current</span>
                  </a>
                </li>
                <li>
                <a href={`https://${this.state.site}.ebagroup.com/users/list_detections_test.php`}>
                    <span>New</span>
                  </a>
                </li>
              </ul>

            </li>
            <li className="topmenu">
              <a href={`https://${this.state.site}.ebagroup.com/users/reports_method.php`}>
                <span>Tables</span>
              </a>
            </li>
            <li className="topmenu">
              <a href={`https://${this.state.site}.ebagroup.com/users/compareh.php`}>
                <span>Historical Comparison</span>
              </a>
            </li>
            <li className="topmenu">
              <a href={`https://${this.state.site}.ebagroup.com/users/data.php`}>
                <span>Data</span>
              </a>
              <ul>
                <li>
                  <a href={`https://${this.state.site}.ebagroup.com/users/list_sites.php`}>
                    <span>Site List</span>
                  </a>
                </li>
                <li>
                  <a href={`https://${this.state.site}.ebagroup.com/users/list_wells.php`}>
                    <span>Well List</span>
                  </a>
                </li>
                <li>
                  <a href={`https://${this.state.site}.ebagroup.com/users/list_fix.php`}>
                    <span>Fix Data</span>
                  </a>
                </li>
                <li>
                  <a href={`https://${this.state.site}.ebagroup.com/users/list_units.php`}>
                    <span>Units</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="topmenu">
              <a href={`https://${this.state.site}.ebagroup.com/users/help.php`}>
                <span>Help</span>
              </a>
              <ul>
                <li>
                  <a href="/user/issues">Issue List</a>
                </li>
              </ul>
            </li>
            <li className="topmenu">
              <a href="/logout">
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </nav>
        </div>
      );
    } else {
      return (
        <div>
          {secure}
        <nav id="menucontainer">
          <ul id="menu" className="topmenu">
            <li className="topmenu">
              <a href="/">
                <span>Home</span>
              </a>
            </li>
            <li className="topmenu">
              <a href="/login">
                <span>Login</span>
              </a>
            </li>
          </ul>
        </nav>
        </div>        
      );
    }
  }
}

export default Menu;
