import React from "react";
import DatePicker from "../components/date-picker";
import ReactMarkdown from 'react-markdown';

const config = require("../utilities/config");
const helper = require("../utilities/helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const moment = require("moment");

// https://reactjs.org/docs/lists-and-keys.html react lists

var g_link = "/user/issues";

// https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '[' + url + '](' + url + ')';
  })
}

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return (
    <tr id = {`row${props.item.id}`}>
      <td>{props.item.description}</td>
      <td><ReactMarkdown>{urlify(props.item.body)}</ReactMarkdown></td>
      <td>{props.item.type}</td>
      <td>{moment(props.item.date).format("MM/DD/YYYY")}</td>
      <td>{props.item.status == 1 ? "Active" : "Inactive"}</td>
      <td>
        <a href={props.item.url} target="_blank">{props.item.url}</a>
      </td>
      <td align="center">
        <button
          type="button"
          class="btn btn-outline-primary"
          onClick={() => {
            props.parent.showModal(props.item);
          }}
        >
          <i className="fa fa-edit mr-2" aria-hidden="true"></i>Edit
        </button>
      </td>
    </tr>
  );
}

class ModalList1 extends React.Component {
  constructor(props) {
    super(props);
    var q = helper.parseQuery();
    var status = q.status;
    if (!status)
      status = 1;

    this.state = {
      show: false,
      rows: null,
      name: null,
      email: "",
      error: null,
      image: null,
      status: status,
      dateStart: null,
      dateEnd: null,
      selectedRow: null
    };
  }

  fillList() {
    var rows1 = [];

    helper
      .getFromServer(
        g_link + "?dw=get" + "&status=" + encodeURI(this.state.status)
      )
      .then((res) => {
        this.setState({
          showForm: false,
          rows: res.result,
          options: res.options,
        });
      });
  }

  componentDidMount() {
    this.fillList();
  }

  setDateFilter(startDate, endDate) {
    this.setState({ dateStart: startDate, dateEnd: endDate });
  }

  showModal(item) {
    //    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    document.getElementById(`row${item.id}`).className = "selected";
    this.setState({
      showForm: true,
      id: item.id,
      description: item.description,
      body: item.body ? item.body : "",
      type: item.type,
      date: item.date,
      activeStatus: item.status,
      url: item.url ? item.url : "",
      error: "",
      selectedRow: item.id,
    });
  }

  hideModal() {
    document.getElementById(`row${this.state.selectedRow}`).className = "";

    this.setState({ showForm: false, selectedRow: null });
  }

  saveItem() {
    //    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper
      .postToServer(
        g_link,
        {
          dw: "update",
          id: this.state.id,
          description: this.state.description,
          body: this.state.body,
          type: this.state.type,
          date: this.state.date,
          status: this.state.activeStatus,
          url: this.state.url,
        },
        true
      )
      .then((res) => {
        this.fillList();
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }
  addItem() {
    helper
      .postToServer(g_link, { dw: "add" }, true)
      .then((res) => {
        this.fillList();
      })
      .catch((err) => {
        alert("AU Said : " + err);
      });
  }

  deleteItem() {
    helper
      .postToServer(g_link, { dw: "del", id: this.state.id }, true)
      .then((res) => {
        this.fillList();
      })
      .catch((err) => {
        alert("AU Said : " + err);
      });
  }

  hic(event) {
    const target = event.target;
    const name = target.name;

    let value;
    //    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === "checkbox") {
      value = this.state[name];
      if (!value) value = [];
      // assumes DOM won't feed us dupes
      var vint = parseInt(target.value);

      if (target.checked) {
        value.push(vint);
      } else {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    } else {
      value = target.value;
    }
    this.setState({
      [name]: value,
    });
  }

  onFile() {
    var file = document.getElementById(undefined).files[0];

    var formData = new FormData();
    formData.append("file", file);
    formData.append("id", this.state.id);
    formData.append("dw", "saveFile");

    fetch(config.back + g_link + "_up", {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({
          image:
            "/api/media/!!upload_tag!!" +
            this.state.id +
            ".png?" +
            new Date().getMilliseconds(),
          alert: "File was uploaded",
        });
      })
      .catch((error) => {
        alert(error);
      });
    //.then(response => console.log('Success:', response));
  }

  formatDate(date) {
    let newDate = date;

    if (newDate) {
      newDate = newDate.substring(0, 10);
    }

    return newDate
  }

  render() {
    document.title = "Groundwater Data Warehouse";
    let listItems;
    /**/

    if (this.state.rows) {
      var statusList = this.state.options.statusList.map((item) => (
        <option value={item.id}>{item.name}</option>
      ));

      if (this.state.dateStart && this.state.dateEnd) {
        listItems = this.state.rows
          .filter(
            (element) => 
              element.date >= this.state.dateStart &&
              element.date <= this.state.dateEnd 
          )
          .map((item, index) => (
            <ListItem key={index} item={item} parent={this} />
          ));
      } else {
        listItems = this.state.rows.map((item, index) => (
          <ListItem key={index} item={item} parent={this} />
        ));
      }
    }

    return (
      <div>
        View Status:{" "}
        <select
          name="status"
          value={this.state.status}
          onChange={this.hic.bind(this)}
        >
          {statusList}
        </select>
        <br />
        <button
          onClick={() => {
            window.location =
              "/user/issues?" + "status=" + encodeURI(this.state.status);
          }}
        >
          Refresh
        </button>
        <br />
        <br />
        <DatePicker setDateFilter={this.setDateFilter.bind(this)} />
        <br />
        <br />
        <button onClick={this.addItem.bind(this)}>Add Item</button>
        <br />
        <table className="table">
          <td>Name</td>
          <td>Description</td>
          <td>Type</td>
          <td>Date</td>
          <td>Status</td>
          <td>url</td>
          <tbody>{listItems}</tbody>
        </table>
        <Modal
          show={this.state.showForm}
          handleClose={this.hideModal.bind(this)}
        >
          <br />
          <div>
            <TextPrompt
              name="description"
              title="Issue"
              onChange={this.hic.bind(this)}
              value={this.state.description}
            />
          </div>
          <div>
            <label>Description:</label>
            <br />
            <textarea
              name="body"
              onChange={this.hic.bind(this)}
              value={this.state.body} 
              rows={4}
              cols={75} />
          </div>
          <div>
            <TextPrompt
              name="type"
              title="Issue Type"
              onChange={this.hic.bind(this)}
              value={this.state.type}
            />
          </div>
          <div>
            <label>Date:</label>
            <br />
            <input
              name="date"
              type="date"
              onChange={this.hic.bind(this)}
              value={this.formatDate(this.state.date)}
            />
          </div>
          <div>
            <label>Status:</label>
            <br />
            <select
              name="activeStatus"
              onChange={this.hic.bind(this)}
              value={this.state.activeStatus}
            >
              <option value={0}>Inactive</option>
              <option value={1}>Active</option>
            </select>
          </div>
          <div>
            <TextPrompt
              name="url"
              title="url"
              onChange={this.hic.bind(this)}
              value={this.state.url}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.saveItem.bind(this)}
          >
            Save
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.deleteItem.bind(this)}
          >
            Delete
          </button>
          &nbsp;
        </Modal>
      </div>
    );
  }
}

export default ModalList1;
