//import PropTypes from 'prop-types';
import "./App.css"
const React = require("react")
const Component = React.Component
const helper = require("./utilities/helper");


//export const formConfirm = FormConfirm;


class OutsideAlerter extends Component {
    constructor(props) {
      super(props);
// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component  
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

  /*
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  */
    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
      this.wrapperRef = node;
    }
  
    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
//        alert('You clicked outside of me!');
        console.log("click!");
      }
    }
  
    render() {
      return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
  }
  
  /*
  OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired,
  };
  */


class TextAreaPrompt extends Component {
  render() {
    return(
      <div className="form-group row">
        <label htmlFor="description" className="col-sm-2 col-form-label">{this.props.title}:</label>
        <div className="col-sm-6">
          <textarea  className="form-control"  rows="4" onChange={this.props.onChange} value={this.props.value} id={this.props.eid} name={this.props.name} />
        </div>
      </div>
    )
  }
}

export const textAreaPrompt = TextAreaPrompt

class TextPrompt extends Component {
 
render()
{
    return(
<div className="form-group row">
  <label htmlFor="name" className="col-sm-2 col-form-label">{this.props.title}:</label><div className="col-sm-7">
  <input className="form-control" type='text' onChange={this.props.onChange} id={this.props.eid} value={this.props.value} name={this.props.name} />
  </div>
</div>
    )
}
}

class EmailPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: props.value };
//            name: props.name
      }
 
render()
{
    return(
<div class="form-group row">
  <label for="name" class="col-sm-2 col-form-label">{this.props.title}:</label><div class="col-sm-7"><input class="form-control" type='email' id={this.props.eid} onChange={this.props.onChange1} value={this.props.value} name='name' /></div>
</div>
    )
}
}

export const textPrompt = TextPrompt

class FormContact extends Component {
    constructor(props) {
       super(props);
       this.state = { hasError: false };
   
     }
  
handleSend()
{

    // no need for config.back or api cuz helper does it
    helper.postToServer("/psupport", {email: document.getElementById("email").value, message: document.getElementById("message").value})
    .then(resp => {
        alert("Thanks for your message.  We will be in touch shortly.")
        document.getElementById("email").value = ""
        document.getElementById("message").value = ""

    })
    .catch(err => {
        alert(err)
    })

}

  render()
    {
      return(<div className="container">
      <br/>
      Please enter your message.
      <br/>
      <br/>
      <EmailPrompt title="Email" value={this.state.email} eid="email" />
      <TextAreaPrompt title="Message" value={this.state.message} eid="message"/>
      <input type="button" className="btn btn-primary" onClick={this.handleSend.bind(this)} value="Send"/>
      </div>)
    }
  
  
    } 
  
export const formContact = FormContact

class CompPager extends Component{
constructor(props){
    super(props)
    var nPages = props.nRows/props.pageSize
    var p = props.offset-props.pageSize
    if (p < 0)
        p = 0
    var n = props.offset+props.pageSize
    if (n > props.nRows-1)
        n = props.nRows-1
    this.state = {
        nPages: nPages,
        p: p,
        n: n,
    }

}
render() {
    var middle = []
    for (var i = 0; i < this.state.nPages; i++)
    {
        middle.push(<a className="w3-button" href={this.props.link+"?o="+i*this.props.pageSize}>{i+1}</a>)
    }

    return(
    <div className="w3-bar">
    <a className="w3-button" href={this.props.link+"?o="+this.state.p}>Previous</a>
    {middle}
    <a className="w3-button" href={this.props.link+"?o="+this.state.n}>Next</a>
  </div>
    )

}
}

export const compPager = CompPager

class CompSearch extends Component {

    render()
    {
        return(
            <div>Topics:</div>
        )
    }
    }
    
    export const compSearch = CompSearch
    
    class CompSpace extends Component {
    /// OE??!!! Note - this won't work with weird CSS float styles
        render()
        {
            return(
                <div>&nbsp;</div>
            )
        }
        }
        
    export const compSpace = CompSpace
    
    
    class CompList extends Component {

        render(){

            return(
                <div className="w3-row" key="0">{this.props.children}</div>
            )

        }

    }
export const compList = CompList

    class CompListItem extends Component {

        render(){

            return(
                <div className="col-sm">{this.props.children}</div>
            )

        }

    }
export const compListItem = CompListItem

/*
const Modal = ({ handleClose, show, children }) => {
    const showHideClassname = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassname}>
        <section className="modal-main">
        <div className="container">
  
          {children}
          <button onClick={handleClose}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  };
*/

class Modal extends React.Component {

render()
{
    return (
      <div className={this.props.show ? "modal display-block" : "modal display-none"}>
        <section className="modal-main" id={this.props.id}>
        <div className="container">
  
          {this.props.children}
          <button onClick={this.props.handleClose}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  }

}

  export const modal = Modal;


  class ModalForm extends React.Component {

    constructor(props)
    {
      super(props);
      this.state = {
        value: null,
        postItem:null,
      };
    }
/*    setData(data)
    {
        this.setState({value: data.content});

    } */
    componentDidMount()
    {
// it's mounted invisible.  We mainly care about when it's popped
    }
    handleInputChange(event) {

      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
    
      console.log(name)
      this.setState({
        [name]: value
      });
    
    }
    
    render()
    {
        return (
          
          <div className={this.props.show ? "modal display-block" : "modal display-none"}>
            <section className="modal-main" id={this.props.id}>
            <div className="container">
      Value: <input type="text" name="value" value={this.state.value} onChange={this.handleInputChange.bind(this)} /><br/>
              {this.props.children}
              <button onClick={() => {
                this.state.updateItem();
              }}>Save</button>
              <button onClick={() => {
                this.state.deleteItem();
              }}>Delete</button>

              <button onClick={() => {
                this.props.parent.setState({modalShowing:false});
              }}>Close</button>

              </div>
              <br/>
      
            </section>
          </div>
        );
      }
    
    }
    
export const modalForm = ModalForm;

  class MessageBox extends React.Component
  {
  
    handleClose()
    {
        this.props.parent.setState({showMessage: null});
    } 

  render()
  {
  
    const showHideClassname = this.props.parent.state.showMessage ? "modal display-block" : "modal display-none";
  

    return (
      <div className={showHideClassname}>
        <section className="modal-main">
        <div className="container">
        <br/>
          {this.props.parent.state.showMessage}<br/><br/>
          <button onClick={this.handleClose.bind(this)}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  }
}
export const messageBox = MessageBox;


function addCommas(nStr)
{
	nStr += '';
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2');
	}
	return x1 + x2;
}

class DataTable extends Component 
{
  constructor(props) 
  {
    super(props);
    var o = 0;
    var d = 0;
    if (props.order)
    {
      o = props.order;
      d = 1;
    }
    
    this.state = { 
      col: o,
      dir: d,
      keys: [],
          };
    this.columnClick = this.columnClick.bind(this);
  }

columnClick(which)
{

  var dir = this.state.dir;
  ++dir;
  if (dir > 1)
    dir = 0;
  this.setState({col: which.j, dir: dir})

}

componentDidMount() {


  var compares = JSON.parse(sessionStorage.getItem("compares"));
  if (!compares)
    compares = [];
  console.log(compares);

  this.setState({keys: compares})


}      

hic(event) {

  const target = event.target;
  const name = target.name;

  let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
  if (target.type === 'checkbox')
  {
    value = this.state[name];
    if (!value)
      value = [];
      // assumes DOM won't feed us dupes
      var vint = target.value;

    if (target.checked)
    {
      value.push(vint);
    }
    else
    {
      var index = value.indexOf(vint);

      if (index !== -1) 
        value.splice(index, 1);

    }
  }
  else
  {
    value = target.value;
  }
  this.setState({
    [name]: value
  });

  console.log(this.state.keys);

}


render() {

    if (!this.state.keys)
    {
      return <div></div>
    }
    var cols = [];
    var i = 0;
    var col = this.state.col;

    cols.push(<td></td>);

    this.props.cols.map(item => {
      var j = i;
      var star = "";
      if (j == col)
        star = "*";
      cols.push(<td><a href="#" onClick={() => {
        this.columnClick({j})
      }
     
      }>{item}{star}</a></td>)
      i++;
    }
    )
    cols.push(<td><button onClick={() => {
      var s = "";
      this.state.keys.map(item => {
        if (s != "")
          s += ","
        s += item;
      })
      sessionStorage.setItem("compares", JSON.stringify(this.state.keys));
      document.location = "/graph1?key="+s;

    }}>Compare</button><button onClick={() => {
        this.setState({keys: []});
    }}>Clear</button></td>)
    var rows = [];

    var sortCol = [];

    var i = 0;
    this.props.data.map(item => {
      sortCol.push([item[col], i++])
    })
    
    if (this.state.dir == 0)
      sortCol.sort(function(a, b){return a[0]-b[0]});
    else
      sortCol.sort(function(a, b){return b[0]-a[0]});

    var data = this.props.data;

//    var key = "US";
//    console.log(this.props.keys)

    var i = 1;
    sortCol.map(item => {
      var line = [];
      line.push(<td>{i}</td>)
      i++;
      var c = 0;
      data[item[1]].map(item1 => {
        if (c > 0)
        {
          item1 = addCommas(item1);
          line.push(<td align="center">{item1}</td>)
        }
        else
        {
          line.push(<td><a href={'/graph1?key='+this.props.keys[item[1]]}>{item1}</a></td>)
        }
        c++;
        
      })

      line.push(<td><input type="checkbox" name="keys" checked={this.state.keys.indexOf(this.props.keys[item[1]]) != -1} value={this.props.keys[item[1]]} onClick={this.hic.bind(this)}/></td>)
      rows.push(<tr key={this.props.keys[item[1]]}>{line}</tr>)
    })

        return(<div>
          <table className="DataTable">
          <thead>
              {cols}
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>

        </div>)
    
    
      } 
  }

export const dataTable = DataTable
