import React from "react";
const config = require("../utilities/config");
const helper = require("../utilities/helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const misc = require("../comp_misc");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/gpoints";
var g_wellGroupId = null;

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.date}</td><td>{props.item.result}</td><td>{props.item.flag}</td>
    <td>{props.item.well}</td><td>{props.item.originalTest}</td>
    <td><a href={`https://tulare.ebagroup.com/users/form_data.php?dw=pup&id=${props.item.dataset}`}>{props.item.sourceFile}</a></td>
    <td>{props.item.methodCode}</td>

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    g_wellGroupId = q.wgId;
    this.state = {
      wellGroupId: q.wgId,
      test: q.test,
      startDate: q.startDate,
      endDate: q.endDate,
    };

  }


  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }



fillList(filter)
{

  helper.getFromServer(g_link+"?test="+this.state.test+"&wgId="+encodeURI(this.state.wellGroupId)+"&startDate="+encodeURI(this.state.startDate)+"&endDate="
      +encodeURI(this.state.endDate))
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options});
  })
}




 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, date: item.date,result: item.result,flag: item.flag, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, date: this.state.date,result: this.state.result,flag: this.state.flag,}, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "add", parentId:this.state.parentId}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Points";
let listItems;
//

if (this.state.rows)
{

  

  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
else
{
  return (<div></div>)
}
return(

<div className="container">
  <br/>
Test: <u>{this.state.test}</u> Date1: {this.state.startDate} Date2: {this.state.endDate}

<table className="table">
    <td>Date</td><td>Result</td><td>Flag</td>
    <td>Well</td>
    <td>Original Test</td>
    <td>Dataset</td>
    <td>Method Code</td>
	<tbody>
    
  {listItems}
  
	</tbody>
  </table>

  <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="date" title="date" onChange={this.hic.bind(this)} value={this.state.date}/></div>
<div><TextPrompt name="result" title="result" onChange={this.hic.bind(this)} value={this.state.result}/></div>
<div><TextPrompt name="flag" title="flag" onChange={this.hic.bind(this)} value={this.state.flag}/></div>

        <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;

</Modal>

</div>
)

}
}

export default ModalList1
