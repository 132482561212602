import React from "react";
import { GoogleLogin } from "react-google-login";

/* import "./App.css"; */

const config = require("../utilities/config.js");
const helper = require("../utilities/helper.js");

// https://developers.google.com/identity/gsi/web/guides/migration#authentication_and_authorization

function finishLogin(response) {
  // OE!!! This is where to put the TOS agreement!!!!
  // check response.tos

  if (response.result === "OK") {
    //    sessionStorage.setItem("connection", cookies.get("connect.sid"))
    sessionStorage.setItem("name", response.name);
    sessionStorage.setItem("uid", response.uid);
    sessionStorage.setItem("site", response.site);

    if (response.roles) {
      if (response.roles.indexOf("admin") !== -1)
        sessionStorage.setItem("admin", true);
    }

    //		this.setState({error: "Worked!"});
    if (sessionStorage.getItem("rd"))
      //      history.replace(sessionStorage.getItem("rd"))
      document.location = sessionStorage.getItem("rd");
    else document.location = "/home";
    //      history.replace('/home')

    sessionStorage.removeItem("rd");
  } else {
    sessionStorage.setItem("name", null);
    this.setState({ error: "Sorry!" });
  }
}

/*
const responseGoogle = (response) => {
  if (
    !response.profileObj.email.endsWith("@ebagroup.com") &&
    !response.profileObj.email === "andrewoplis@gmail.com"
  ) {
    alert("Sorry only EBA Logins");
    return;
  }

  fetch(config.back + "/loging", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(response),
  })
    .then((res) => res.json())
    .catch((error) => console.error("Error:", error))
    .then((response) => {
      finishLogin(response);
    });
};
*/

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      error: "",
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ loading: true });

    helper
      .postToServer("/login", {
        user: event.target.elements.email.value,
        password: event.target.elements.password.value,
      })
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
        finishLogin(res);
      })
      .catch((err) => {
        alert(err);
      });
  }

  responseGoogle(response) {
    if (
      !response.profileObj.email.endsWith("@ebagroup.com") 
//      && !response.profileObj.email === "andrewoplis@gmail.com"
    ) {
      alert("Sorry only EBA Logins");
      return;
    }

    this.setState({loading: true});
  
    fetch(config.back + "/loging", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(response),
    })
      .then((res) => res.json())
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        this.setState({loading: false});
        finishLogin(response);
      });
  }

  render() {
    document.title = "Login";
    if (!this.state.loading) {
      return (
        <div className="container">
          <p>Login with Data Warehouse account:</p>
          <form onSubmit={this.handleSubmit}>
            <div className="col-md-6">
              <input
                type="text"
                name="email"
                className="form-control"
                id="email"
                placeholder="Username"
                autoFocus
              />
              <br />
              <input
                type="password"
                name="password"
                className="form-control"
                id="password"
                placeholder="Password"
              />
              <br />

              {this.state.error}
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
          </form>
          <p><b>or</b></p>
          <GoogleLogin
            clientId="386203500151-h3jr9ootqcduvpem8k96o7c9gma3l386.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.responseGoogle.bind(this)}
            onFailure={(res) => {alert("Error with Google Login:\n\n" + res.details)}}
          />
        </div>
      );
    } else {
      return (
        <div className="container">
          <i className="fa fa-spinner fa-spin fa-5x" style={{color:"#54bbff"}} />
        </div>
      );
    }
  }
}

export default Login;
