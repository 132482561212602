import React from "react";
import DatePicker from "../components/date-picker";

const config = require("../utilities/config");
const helper = require("../utilities/helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/graph_sets";

var g_wellGroupId;

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  var link = "/user/graph?test="+props.item.test+"&wgId="+g_wellGroupId+"&startDate="+props.startDate+"&endDate="+props.endDate;
  var link1 = "/user/gpoints?test="+props.item.test+"&wgId="+g_wellGroupId+"&startDate="+props.startDate+"&endDate="+props.endDate;
  return (<tr>
    <td><a href={link}>{props.item.test}</a></td><td>{props.item.count}</td>
    <td><input defaultChecked={(props.item.show == true) ? true : false} type="checkbox" onChange={(event) => {

      helper.postToServer(g_link, {dw: "select", wgId: g_wellGroupId, show: event.target.checked, test: props.item.test})
      .then(res => {
        console.log(res);
      })
//      console.log(event.target.checked)

    }} /></td>
    <td><a href={link1}>Points</a></td>
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>
  )
}

class ModalList1 extends React.Component {
  
  // DATA FLOW:
  //
  // 1. Refresh button sets URL with given variables
  // 2. Page freshes
  // 3. fillLIst takes the variables in the page URL and sends
  // them to the server for a response
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    g_wellGroupId = q.wellGroupId;
    this.state = {
      show: false,
      rows: null,
      name: null,
      email: "",
      error: null,
      cat: q.cat,
      wellGroupId : q.wellGroupId,
      startDate: q.startDate,
      endDate: q.endDate,
    };
  }

  componentDidMount() {
    this.fillList();
  }


  fillList()
  {
    var rows1 = [];

    helper.getFromServer(g_link+"?dw=get"+"&wellGroupId="+encodeURI(this.state.wellGroupId)+"&startDate="+encodeURI(this.state.startDate)+"&endDate="+encodeURI(this.state.endDate))
    .then(res => {

      this.setState({showForm: false, rows: res.result, options: res.options});
    })
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, test: item.test,count: item.count, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, test: this.state.test, count: this.state.count,}, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  printGraphs()
  {

    window.location = "/user/graph?"+"wgId="+encodeURI(this.state.wellGroupId)+"&startDate="+encodeURI(this.state.startDate)+"&endDate="+encodeURI(this.state.endDate);
    
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }

  setDateFilter(startDate, endDate, cat) {
    if (this.state.startDate != startDate || this.state.endDate != endDate)
      document.location = `/user/graph_sets?wellGroupId=${this.state.wellGroupId}&startDate=${startDate}&endDate=${endDate}&cat=${cat}`;
//    this.setState({ startDate: startDate, endDate: endDate });
  }

  render() {
    document.title = "Groundwater Data Warehouse";
    let listItems;
    let wells;
    /*let wellGroupList;
    */

    if (this.state.rows)
    {
      var wellGroupList = this.state.options.wellGroupList.map((item, index) =>
        <option key={index} value={item.id}>{item.name}</option>
      );

      wellGroupList.unshift(<option value="-1">Please select</option>);
      
      listItems = this.state.rows.map((item, index) =>
        <ListItem key={index} item={item} startDate={this.state.startDate} endDate={this.state.endDate} parent={this} />
      );

      wells = this.state.options.wells;
    }

    return (
      <div>
        Well Group: <select name='wellGroupId' value={this.state.wellGroupId} onChange={this.hic.bind(this)}>{wellGroupList}</select>
        <br />
        Wells: {wells}<br/>
        <br />
        <DatePicker cat={this.state.cat} setDateFilter={this.setDateFilter.bind(this)} cat={this.state.cat} startDate={this.state.startDate} endDate={this.state.endDate} />
        <br />
        <button onClick={() => {
          if (!this.state.startDate || !this.state.endDate) {
            alert(`Please select valid date range\n\nSelected Range: ${this.state.startDate} to ${this.state.endDate}`);
          } else {
            window.location = "/user/graph_sets?"+"wellGroupId="+encodeURI(this.state.wellGroupId)+"&startDate="+encodeURI(this.state.startDate)+"&endDate="+encodeURI(this.state.endDate);
          }
        }}>
          Refresh
        </button>
        <br />
        <br />
        <button onClick={this.printGraphs.bind(this)}>Print Graphs</button><br/><br/>
        <table className="table">
          <thead>
            <tr>
              <td><b>Test</b></td>
              <td><b>Count</b></td>
              <td><b>Show Graph</b></td>
              <td><b>View Points</b></td>
            </tr>
          </thead>
          <tbody>
            {listItems}
          </tbody>
        </table>

        <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="test" title="test" onChange={this.hic.bind(this)} value={this.state.test}/></div>
          <div><TextPrompt name="count" title="count" onChange={this.hic.bind(this)} value={this.state.count}/></div>

          <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
          <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;
        </Modal>
      </div>
    )
  }
}

export default ModalList1
