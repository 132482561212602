
//var back1 = 'https://localhost:3010/api';
var back1 = window.location.origin+"/api";
var client1 = true;
var name1 = "Data Warehouse"
var hostname = window.location.hostname;

if (hostname.indexOf("wh1.ebagroup.com") != -1)
{
    back1 = "https://wh1.ebagroup.com:/api"
    client1 = false;
    name1 = "Data Warehouse"
}

if (hostname.indexOf("merced.ebagroup.com") != -1)
{
    document.location = "/login.php"
}


export const back = back1;
export const client = client1;
export const name = name1;
