import React from "react";
import * as Script from "react-load-script";
import ReactToPrint from "react-to-print";

import "../css/graph1.css";

const helper = require("../utilities/helper");

var titleStyle = { fontWeight: "bold" };

var g_link = "/user/graph";

var g_wellGroupId;

class Chart extends React.Component {
  state = {
    dataLength: 0,
  }
  jValues = [];

  fillData(data) {
    this.props.data.wells.map((item) => {
      data.addColumn("number", item);
      data.addColumn({ type: "string", role: "style" });
    });

    var currentDate = "";
    var rowNo = -1;
    let cd;

    //console.log(this.props.data.detections);
    this.setState({dataLength: this.props.data.detections.length});

    var id = 0;
    this.props.data.detections.map((samp) => {
      if (currentDate != samp.date) {
        data.addRows(1);
        ++rowNo;
        currentDate = samp.date;
        cd = new Date(currentDate);
      }
      data.setCell(rowNo, 0, cd);
      data.setCell(rowNo, 2 * samp.wellNo + 1, samp.value);
      if (samp.flag)
      {
        this.jValues.push({x: samp.date, y: samp.value, id: id++});
      }
    });
  }

  sampleData(data) {
    data.addColumn("number", "M-12");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-13B");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-14B");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-16A");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-16B");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-21B");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-23B");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-2B");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "M-3B");
    data.addColumn({ type: "string", role: "style" });

    
    // wellcount 9
    data.addRows(1);
    data.setCell(0, 0, new Date(2016, 3, 22));
    data.setCell(0, 3, 2.3);
    data.setCell(0, 0, new Date(2016, 3, 22));
    data.setCell(0, 5, 0.6);
    data.setCell(0, 0, new Date(2016, 3, 22));
    data.setCell(0, 9, 0.66);

    data.addRows(1);
    data.setCell(1, 0, new Date(2016, 9, 6));
    data.setCell(1, 3, 1.8);
    data.setCell(1, 0, new Date(2016, 9, 6));
    data.setCell(1, 9, 0.74);

    data.addRows(1);
    data.setCell(2, 0, new Date(2016, 9, 7));
    data.setCell(2, 5, 0.56);

    data.addRows(1);
    data.setCell(3, 0, new Date(2017, 3, 13));
    data.setCell(3, 3, 2.0);
    data.setCell(3, 0, new Date(2017, 3, 13));
    data.setCell(3, 5, 0.5);

    data.addRows(1);
    data.setCell(4, 0, new Date(2017, 9, 25));
    data.setCell(4, 3, 2.2);
    data.setCell(4, 0, new Date(2017, 9, 25));
    data.setCell(4, 5, 0.5);

    data.addRows(1);
    data.setCell(5, 0, new Date(2017, 9, 27));
    data.setCell(5, 11, 0.58);
    data.setCell(5, 0, new Date(2017, 9, 27));
    data.setCell(5, 13, 0.5);

    data.addRows(1);
    data.setCell(6, 0, new Date(2018, 2, 19));
    data.setCell(6, 13, 0.99);

    data.addRows(1);
    data.setCell(7, 0, new Date(2018, 3, 17));
    data.setCell(7, 5, 0.5);

    data.addRows(1);
    data.setCell(8, 0, new Date(2018, 3, 18));
    data.setCell(8, 3, 2.4);
    data.setCell(8, 0, new Date(2018, 3, 18));
    data.setCell(8, 7, 5.4);

    data.addRows(1);
    data.setCell(9, 0, new Date(2018, 3, 25));
    data.setCell(9, 13, 0.78);

    data.addRows(1);
    data.setCell(10, 0, new Date(2018, 3, 26));
    data.setCell(10, 1, 0.78);
    data.setCell(10, 0, new Date(2018, 3, 26));
    data.setCell(10, 15, 12);

    data.addRows(1);
    data.setCell(11, 0, new Date(2018, 9, 19));
    data.setCell(11, 7, 4.7);
    data.setCell(11, 0, new Date(2018, 9, 19));
    data.setCell(11, 13, 0.62);

    data.addRows(1);
    data.setCell(12, 0, new Date(2018, 9, 25));
    data.setCell(12, 1, 1.4);

    data.addRows(1);
    data.setCell(13, 0, new Date(2019, 0, 3));
    data.setCell(13, 13, 0.57);

    data.addRows(1);
    data.setCell(14, 0, new Date(2019, 3, 15));
    data.setCell(14, 1, 1.3);
    data.setCell(14, 0, new Date(2019, 3, 15));
    data.setCell(14, 13, 0.5);
    data.setCell(14, 0, new Date(2019, 3, 15));
    data.setCell(14, 15, 14);

    data.addRows(1);
    data.setCell(15, 0, new Date(2019, 3, 17));
    data.setCell(15, 7, 4.7);

    data.addRows(1);
    data.setCell(16, 0, new Date(2019, 9, 7));
    data.setCell(16, 1, 1.3);
    data.setCell(16, 0, new Date(2019, 9, 7));
    data.setCell(16, 13, 0.55);

    data.addRows(1);
    data.setCell(17, 0, new Date(2019, 9, 10));
    data.setCell(17, 7, 4.8);

    data.addRows(1);
    data.setCell(18, 0, new Date(2019, 9, 21));
    data.setCell(18, 15, 15);
    data.setCell(18, 0, new Date(2019, 9, 21));
    data.setCell(18, 17, 11);

    data.addRows(1);
    data.setCell(19, 0, new Date(2020, 3, 14));
    data.setCell(19, 13, 0.5);
    data.setCell(19, 0, new Date(2020, 3, 14));
    data.setCell(19, 15, 17);
    data.setCell(19, 0, new Date(2020, 3, 14));
    data.setCell(19, 17, 15);

    data.addRows(1);
    data.setCell(20, 0, new Date(2020, 3, 23));
    data.setCell(20, 7, 4.6);

    data.addRows(1);
    data.setCell(21, 0, new Date(2020, 3, 24));
    data.setCell(21, 11, 0.5);





    /*
    data.addRows(1);
    data.setCell(0, 0, new Date(2018, 3, 26));
    data.setCell(0, 15, 12);

    data.addRows(1);
    data.setCell(1, 0, new Date(2019, 3, 15));
    data.setCell(1, 15, 14);
    */
  }

  drawChart() {
    //    console.log(this.props);

    var data = new window.google.visualization.DataTable();

    // Add columns
    data.addColumn("date", "Test Date");

    
    if (!this.props.data) 
      this.sampleData(data);
    else 
      this.fillData(data);
      

    //this.sampleData(data);

    var units = this.props.units;

    var chart = new window.google.visualization.LineChart(
      document.getElementById(this.props.chartId)
    );

    var options = {
      legend: {
        position: "right",
        textStyle: { fontSize: 14 },
      },

      pointSize: 14,
      fontSize: 16,
      interpolateNulls: true,
      //          title: '1,1-Dichloroethane Visalia',
      hAxis: {
        title: "Date",
        titleTextStyle: { bold: true, italic: false },
        fontSize: 12,
        format: "MMM yyyy",
        //    ticks: [new Date(2016,1,1), new Date(2018,1,1)],
      },
      vAxis: {
        title: `Concentration (${units})`,
        titleTextStyle: { bold: true, italic: false },
        fontSize: 12,
      },
      extra: ["1,1-dichloroethane", "9"],
      colors: [
        "#f0a3ff",
        "#0075dc",
        "#993f00",
        "#4c005c",
        "#191919",
        "#005c31",
        "#2bce48",
        "#ffcc99",
        "#808080",
        "#94ffb5",
        "#8f7c00",
        "#9dcc00",
        "#c20088",
        "#003380",
        "#ffa405",
        "#ffa8bb",
        "#426600",
        "#ff0010",
        "#5ef1f2",
        "#00998f",
        "#18ff66",
        "#100aff",
        "#350000",
        "#19ff80",
        "#37ff00",
        "#375005",
        "#7aff66",
        "#740aff",
        "#0d0000",
        "#ffff80",
        "#9bff00",
        "#9b5005",
        "#2cff66",
        "#2c0aff",
        "#2c0000",
        "#22ff80",
        "#21ff00",
        "#215005",
        "#18ff66",
        "#d80aff",
        "#990000",
        "#9bff80",
        "#ffff00",
        "#ff5005",
      ],
    };

    // this is setting it to 0
    //this.setState({dataLength: data.getNumberOfRows()});

    window.google.visualization.events.addListener(
      chart,
      "ready",
      this.placeMarker.bind(chart, data, this.props.chartId, this.props.data.detections, this.jValues)
    );
    chart.draw(data, options);
  }

  /*
  function() {
  
        var cli = chart.getChartLayoutInterface();
    
        for (var i = 0; i < data.getNumberOfRows(); ++i) {
          (function(row) {
            console.log(row);
            var condition = data.getProperty(row, 0, 'c');
            if (condition) {
              var icon = new Image(),
                left = Math.round(cli.getXLocation(data.getValue(row, 0)));
              icon.src = 'http://icons.wxug.com/i/c/k/' + condition + '.gif';
              icon.style.cssText = 'left:' + left + 
                 'px;position:absolute;margin-top:-20px;width:20px;height:20px;'
              document.querySelector('#comb_chart_div>.Weather_Chart_CurrentForecast')
                .appendChild(icon);
            }
          }(i))
        }
      }
  */

  placeMarker(dataTable, chartId, detections, jValues) {
    console.log("giving j values")
//    console.log(dataTable);
    console.log(jValues);
    console.log(jValues.length)
    var cli = this.getChartLayoutInterface();
    var chartArea = cli.getChartAreaBoundingBox();
    
    // this is buggy but should work for what we want to do
//    for (let i = 0; i < detections.length; i++) {
    for (let i = 0; i < jValues.length; i++)  
    {   
      
      let x = cli.getXLocation(new Date(jValues[i].x));
      let y = cli.getYLocation(jValues[i].y);
      
      let p = document.getElementById("points" + chartId + jValues[i].id);
      p.style.left = x - 4.5 + "px";
      p.style.top = y - 13 + "px";
    }
    
        /*
    let rows = dataTable.getNumberOfRows();
    let columns = dataTable.getNumberOfColumns();
    let count = 0;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        let item = dataTable.getValue(i, j);
        
        if (item) {
          console.log("Item", item);
          console.log(item instanceof Date)l
          let x = cli.getXLocation(item instanceof Date ? new Date(item) : item);
          let y = cli.getYLocation(item instanceof Date ? new Date(item) : item);
          //console.log(x, y);

          let p = document.getElementById("points" + chartId + count);
          p.style.left = x - 4.5 + "px";
          p.style.top = y - 13 + "px";
          count++;
        } 
      }
    }
    */
  }

  onLoad() {
    window.google.charts.load("current", { packages: ["corechart"] });
    window.google.charts.setOnLoadCallback(this.drawChart.bind(this));
  }

  onError() {}

  render() {
    var chartId = this.props.chartId;
    let points = [];
    console.log("data length " + this.state.dataLength);
    for(let i = 0; i < this.state.dataLength; i++) {
      points.push(
        <div key={"points"+chartId+i} id={"points"+chartId+i} className="overlay-marker">
          <img src={require("../media/whitedot.png")} border="false" />
        </div>
      );
    }

    return (
      <div>
        <Script
          url="https://www.gstatic.com/charts/loader.js"
          onError={() => {
            this.onError().bind(this);
          }}
          onLoad={this.onLoad.bind(this)}
        />
        <div id={chartId + "_container"} className="chartWithMarkerOverlay">
          <div
            id={chartId}
            style={{ pageBreakAfter: "avoid", width: "900px", height: "500px" }}
          ></div>
          {points}
          {
          /*
          <div id={"points"+chartId+"0"} className="overlay-marker">
            <img src={require("../media/whitedot.png")} border="false" />
          </div>
          <div id={"points"+chartId+"1"} className="overlay-marker">
            <img src={require("../media/whitedot.png")} border="false" />
          </div>
          */
          }
        </div>
      </div>
    );
  }
}

class Graph extends React.Component {
  constructor(props) {
    super(props);
    var q = helper.parseQuery();
    g_wellGroupId = q.wgId;
    this.state = {
      wellGroupId: q.wgId,
      test: q.test,
      startDate: q.startDate,
      endDate: q.endDate,
    };
  }

  componentDidMount() {
    helper
      .getFromServer(
        "/user/graph?test=" +
          this.state.test +
          "&wgId=" +
          this.state.wellGroupId +
          "&startDate=" +
          this.state.startDate +
          "&endDate=" +
          this.state.endDate
      )
      .then((res) => {
        console.log(res.result);
        this.setState({ data: res.result });
      });
  }

  render() {
    //    document.title = "Groundwater Data Warehouse";
    var charts = [];

    if (this.state.data) {
      var graphs = this.state.data.graphs;
      for (var i = 0; i < graphs.length; i++) {
        var graph = graphs[i];
        //        console.log(graph);

        charts.push(
          <div key={i}>
            <div style={titleStyle}>{this.state.data.siteName}</div>
            <div style={titleStyle}>{this.state.data.heading}</div>
            <div style={titleStyle}>{graph.test}</div>
            <div style={titleStyle}>
              {"Date Range: " +
                this.state.startDate +
                " to " +
                this.state.endDate}
            </div>
            <Chart data={graph} units={graph.units} chartId={"graph" + i} />
            {/* 
            

            <div id="points0" className="overlay-marker">
              <img
                src="https://developers.google.com/chart/interactive/images/zombie_150.png"
                border="false"
              />
            </div>
            <div id="points1" className="overlay-marker">
              <img
                src="https://developers.google.com/chart/interactive/images/zombie_150.png"
                border="false"
              />
            </div>
              */}
          </div>
        );
      }
    } else {
      return <div>Loading</div>;
    }

    return (
      <div>
        <br />
        {charts}
      </div>
    );
  }
}

class Graph1 extends React.Component {
  constructor(props) {
    super(props);
    var q = helper.parseQuery();
    g_wellGroupId = q.wgId;

    this.state = {
      startDate: q.startDate,
      endDate: q.endDate,
    };
  }

  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <div>
                <a href="#">Print</a>{" "}
                <a
                  href={
                    "/user/graph_sets?wellGroupId=" +
                    g_wellGroupId +
                    "&startDate=" +
                    this.state.startDate +
                    "&endDate=" +
                    this.state.endDate
                  }
                >
                  Graph Sets
                </a>
              </div>
            );
          }}
          content={() => this.componentRef}
        />
        <Graph ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}

export default Graph1;
