import React from "react";

const currentDate = new Date();

const categories = [
  { name: "Last 5 Years", dateBegin: findDate(1, 5*12), dateEnd: findDate() },
  { name: "All", dateBegin: null, dateEnd: null},
  { name: "Custom", dateBegin: null, dateEnd: null },
  { name: "Today", dateBegin: findDate(), dateEnd: findDate() },
  { name: "Yesterday", dateBegin: findDate(1), dateEnd: findDate(1) },
  { name: "This Week", dateBegin: findDate(currentDate.getDay()), dateEnd: findDate() },
  { name: "Last Week", dateBegin: findDate(7 + currentDate.getDay()), dateEnd: findDate(7 - currentDate.getDay()) },
  { name: "Past 7 Days", dateBegin: findDate(7), dateEnd: findDate() },
  { name: "This Month", dateBegin: findDate(currentDate.getDate() - 1), dateEnd: findDate() },
  { name: "Last Month", dateBegin: findDate(1, 1), dateEnd: findDate(currentDate.getDate()) },
  { name: "Past 30 Days", dateBegin: findDate(30), dateEnd: findDate() },
  { name: "Past 6 Months", dateBegin: findDate(1, 6), dateEnd: findDate() },
  { name: "This Year", dateBegin: findDate(1, currentDate.getMonth()), dateEnd: findDate() },
  { name: "Past 12 Months", dateBegin: findDate(1, 12), dateEnd: findDate() },
];

function findDate(dayBuffer, monthBuffer) {
    let curr = new Date();

    if (dayBuffer) {
      if (monthBuffer) {
        curr.setDate(dayBuffer);
        curr.setMonth(curr.getMonth() - monthBuffer);
      } else {
        curr.setDate(curr.getDate() - dayBuffer);
      }
    }

    return curr.toISOString().substr(0,10);
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.cat? this.props.cat : 0,
      dateBegin: this.props.startDate? this.props.startDate : categories[0].dateBegin,
      dateEnd: this.props.endDate? this.props.endDate: categories[0].dateEnd,
    };
    this.setDateFilter = this.props.setDateFilter.bind(this);
    this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
  }

  componentDidMount() {
    this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
  }

  handleSelect(event) {
    const value = event.target.value;

    // Future proofing for potental cases
    if (!categories[value].dateBegin) {
      document.getElementById("dateBegin").value = "";
    }
    if (!categories[value].dateEnd) {
      document.getElementById("dateEnd").value = "";
    }

    // Callback to set the dates in the parent once the initial setState function is finished
    this.setState({ category: value, dateBegin: categories[value].dateBegin, dateEnd: categories[value].dateEnd }, () => {
      this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
    });
  }

  // Man, callbacks really suck, don't they?
  // (Closure just so only silly callback will work correctly)
  checkDate(name, oldDate) {
    return () => {
      if (Date.parse(this.state.dateBegin) > Date.parse(this.state.dateEnd)) {
        this.setState({[name]: oldDate});
        alert("Invalid Date Range");
      } else {
        this.setDateFilter(this.state.dateBegin, this.state.dateEnd);
      }
    }
  }

  handleChange(event) {
    const oldDate = this.state[event.target.name];

    this.setState({[event.target.name]: event.target.value}, this.checkDate(event.target.name, oldDate));
  }

  handleSubmit(event) {
    event.preventDefault();
    
    this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
  }

  render() {
    return (
      <div>
        Date Range:
        <form onSubmit={this.handleSubmit.bind(this)}>
          <label>
            Category:{" "}
            <select
              value={this.state.category}
              onChange={this.handleSelect.bind(this)}
            >
              {categories.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </label>
          <label>
            {" "}Start Date:{" "}
            <input
              id="dateBegin"
              type="date"
              name="dateBegin"
              onChange={this.handleChange.bind(this)}
              value={this.state.dateBegin}
            />
          </label>
          <label>
            {" "}End Date:{" "}
            <input
              id="dateEnd"
              type="date"
              name="dateEnd"
              onChange={this.handleChange.bind(this)}
              value={this.state.dateEnd}
            />
          </label>
          {/*
            {" "}
            <input type="submit" value="Set" />
          */}
        </form>
      </div>
    );
  }
}

export default DatePicker;
