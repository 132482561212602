import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Menu from './components/menu';
import Viewx from './components/viewx';

const helper = require('./utilities/helper');
const config = require('./utilities/config');


/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

function checkName()
  {
    if (sessionStorage.getItem("name"))
      return true;
    sessionStorage.setItem("rd", document.location)
    return false;
  }

const PrivateRoute = ({ component: Component, ...rest }) => 
(
 <Route {...rest} render={(props) => (

	  checkName()	? <Component {...props} />
			: <Redirect to='/login' />
  )
} />
)

function route1() {

  return (
    <Router>
      <div>
      <Menu/>
      <div style={{margin: "20px"}}>

        <Route path="/login" component={require("./components/login").default} />
        <Route path="/logout" component={() => {
          helper.postToServer('/logout').then(res => {
            sessionStorage.clear();
            window.location = "/";
          })
        }} />
        <PrivateRoute path="/user/headings" component={require("./user/list_headings").default} /> 
        <PrivateRoute path="/user/methods" component={require("./user/list_methods").default} />         
        <PrivateRoute path="/user/graph_sets" component={require("./user/list_graph_sets").default} /> 
        <PrivateRoute path="/user/well_groups" component={require("./user/list_well_groups").default} />
        <PrivateRoute path="/user/issues" component={require("./user/list_issues").default} /> 
        <PrivateRoute path="/user/graph" component={require("./user/graph1").default} /> 
        <PrivateRoute path="/user/gpoints" component={require("./user/list_gpoints").default} /> 

{/*
      <Route exact path="/" component={Home} />
      <Route path="/home" component={Home} />
        <PrivateRoute path="/user/projects" component={require("./user/list_projects").default} /> 
        <PrivateRoute path="/user/page" component={require("./comp_page").default} />
        <PrivateRoute path="/user/ana" component={require("./comp_ana").default} />
        <PrivateRoute path="/user/text" component={CompText} />

        <PrivateRoute path="/admin/users" component={require("./admin/list_users").default} />
*/}
      </div>
      </div>                
    </Router>
  );
}

ReactDOM.render(route1(), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
